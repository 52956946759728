<template>
  <div class="sctp-container">
    <div class="pad-tb10">
      <div class="mg-b10">
        <el-breadcrumb separator=">">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>我的服务订单</el-breadcrumb-item>
          <el-breadcrumb-item>订单详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="order-detail">
        <el-row type="flex" :gutter="15">
          <el-col :span="5">
            <div class="sctp-bg-white" style="height: 100%;">
              <div class="flex flex-center" style="font-size: 48px;font-weight: 700;height: 100%;">
                广告
              </div>
            </div>
          </el-col>
          <el-col :span="19">
            <div class="flex-item1">
              <div class="card pad15">
                <div class="sctp-card-title">订单详情</div>
              </div>
              <template v-if="orderDetailConfig.detail">
                <div class="card">
                  <el-card shadow="never">
                    <div class="flex flex-center">
                      <div class="mg-b20">
                        订单状态：<span class="fz-16 sctp-bold">{{ status }}</span>
                      </div>
                    </div>
                    <div>
                      <order-step :step-array="stepArray" :active="currentStep"></order-step>
                    </div>
                  </el-card>
                </div>

                <div class="card order-item">
                  <div
                    class="sctp-flex sctp-mar-b10 sctp-pad15 sctp-flex-aic sctp-font-12 sctp-bg-gray sctp-info">
                    <div class="sctp-mar-r15">订单号：{{ orderDetailConfig.detail.orderno }}</div>
                    <div class="sctp-mar-r15">提交时间：{{
                        dateToString(orderDetailConfig.detail.createtime)
                      }}
                    </div>
                  </div>
                  <div class="goods-list"
                       @click="optionsConfig.onClick('orderCycle')">
                    <el-row type="flex" align="middle" class="sctp-pad15 goods-item">
                      <el-col :span="12">
                        <div class="">
                          <div class="sctp-flex">
                            <el-image
                              class="sctp-mar-r15"
                              :src="orderDetailConfig.detail.serviceinfo.servicecovers[0]"
                              style="width: 80px; height: 80px"
                              fit="cover"></el-image>
                            <div class="sctp-flex-item-1">
                              <router-link
                                target="_blank"
                                :to="`/service/view/${orderDetailConfig.detail.serviceid}`"
                              >
                                <h3>
                                  {{ orderDetailConfig.detail.serviceinfo.servicename }}
                                </h3>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </el-col>
                      <el-col :span="2">
                        <div class="sctp-tc">¥{{
                            orderDetailConfig.detail.serviceinfo.serviceprice
                          }}
                        </div>
                      </el-col>
                      <el-col :span="2">
                        <div class="sctp-tc">
                          {{ status }}
                          <template v-if="orderDetailConfig.detail.status === 8">
                            原因：{{ orderDetailConfig.detail.refusereason }}
                          </template>
                        </div>
                      </el-col>
                      <el-col :span="2">
                        <div class="sctp-tc">
                          <template v-if="orderDetailConfig.detail.hostingamount">
                            {{ orderDetailConfig.detail.hostingamount }}元
                          </template>
                        </div>
                      </el-col>
                      <el-col :span="2">
                        <div class="options-btn-group">
                          <template v-if="orderDetailConfig.detail.status === 1">
                            <el-button
                              size="mini" type="primary"
                              @click="optionsConfig.onClick('hosting')"
                            >
                              托管资金
                            </el-button>
                          </template>
                          <template v-if="orderDetailConfig.detail.status === 4">
                            <el-button
                              size="mini" type="primary"
                              @click="optionsConfig.onClick('accept')"
                            >
                              验收
                            </el-button>
                          </template>
                          <template v-if="orderDetailConfig.detail.status === 5">
                            <el-button
                              size="mini" type="primary"
                              @click="optionsConfig.onClick('pay')"
                            >
                              确认支付
                            </el-button>
                          </template>
                          <template v-if="orderDetailConfig.detail.status === 6">
                            <el-button
                              size="mini" type="primary"
                              @click="optionsConfig.onClick('evaluate')"
                            >
                              去评价
                            </el-button>
                          </template>
                          <template v-if="orderDetailConfig.detail.status === 8">
                            <el-button
                              size="mini" type="primary"
                              @click="optionsConfig.onClick('evaluate')"
                            >
                              退款
                            </el-button>
                          </template>
                        </div>
                      </el-col>
                    </el-row>
                    <div class="pad-tb10 pad-lr15 sctp-info flex feedback">
                      <div class="flex flex-center sctp-pad-lr15">
                  <span
                    style="padding: 12px 15px;color: #ffffff;background-color: #ff9e00;">反馈内容</span>
                      </div>
                      <div class="flex-item1">
                        <div class="flex">
                          <div>期望交付时间：{{
                              dateToString(orderDetailConfig.detail.expectedDeliveryTime)
                            }}
                          </div>
                          <div class="sctp-mar-l20">预算费用：{{
                              orderDetailConfig.detail.budget
                            }}元
                          </div>
                          <div class="sctp-mar-l20">联系方式：{{ orderDetailConfig.detail.phone }}</div>
                        </div>
                        <div class="sctp-pad-tb10">
                          附加要求：{{ orderDetailConfig.detail.remark }}
                        </div>
                        <div class="flex">
                          <template v-if="orderDetailConfig.detail.annex">
                            <template v-for="item in orderDetailConfig.detail.annex.split(',')">
                              <div>{{ item.substring(item.lastIndexOf('/') + 1) }}</div>
                            </template>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </template>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import {service} from '@/apis/index'

export default {
  name: "ServiceOrderDetail",
  components: {
    OrderStep: () => import('./OrderStep'),
  },
  props: {
    id: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      orderDetailConfig: {
        detail: undefined,
        loadDetail: () => {
          service.orderDetails({
            orderId: this.id,
            userId: this.user.userId,
          }).then(res => {
            let {retdata} = res;
            this.orderDetailConfig.detail = retdata;
          })
        },
      },
      optionsConfig: {
        options: {
          hosting: ({item, index}) => {
            this.goPage({
              path: '/service/buy/signingContract',
              name: '服务签订合同',
              query: {
                orderId: item.orderid
              }
            })
          },
          accept: ({item, index}) => {
            this.goPage({
              path: '/service/buy/confirmService',
              query: {
                orderId: item.orderid
              }
            })
          },
          pay: ({item, index}) => {
            this.goPage({
              path: '/service/buy/pay',
              query: {
                orderId: item.orderid
              }
            })
          },
          evaluate: ({item, index}) => {
            this.goPage({
              path: '/service/buy/evaluate',
              query: {
                orderId: item.orderid
              }
            })
          },
          details: ({item, index}) => {
            this.$router.push({
              path: `/order/service/orderDetail/${item.orderid}`,
            })
          },
          end: ({item, index}) => {
            this.$confirm('确认服务完成?', '提示').then(() => {
              service.optionsService({orderId: item.orderid, status: 4}).then(() => {
                this.orderDetailConfig.loadData();
              })
            })
          },
          orderCycle: ({item, index}) => {
            let path = '', query = {
              orderId: item.orderid
            };
            switch (item.status) {
              case 1:
                path = '/service/buy/signingContract';
                break;
              case 4:
                path = '/service/buy/confirmService';
                break;
              case 5:
                path = '/service/buy/pay';
                break;
              case 7:
                path = '/service/buy/evaluate';
                break;
            }
            if (!path) {
              return;
            }
            this.$router.push({
              path: path,
              query: query
            });
          },
        },
        onClick: (option) => {
          let item = this.orderDetailConfig.detail;
          if (this.optionsConfig.options[option]) {
            this.optionsConfig.options[option].call(null, {item: item});
          }
        }
      },
    }
  },
  computed: {
    status() {
      let status = {
        0: {label: '待服务商确认'},
        1: {label: '服务商已确认，待托管资金'},
        2: {label: '买家托管资金'},
        3: {label: '服务商同意开始服务'},
        4: {label: '服务商确认服务完成,待验收'},
        5: {label: '待买家确认收到服务.'},
        6: {label: '买家同意支付托管资金'},
        7: {label: '订单评价'},
        8: {label: '拒绝'},
        9: {label: '订单完成'},
        10: {label: '拒绝待退款'},
      };
      let {status: state} = this.orderDetailConfig.detail;
      if (status[state]) {
        return status[state].label;
      }
      return '';
    },
    currentStep() {
      let active = 1;
      let {status: orderStatus} = this.orderDetailConfig.detail;
      if (orderStatus) {
        switch (orderStatus) {
          case 0:
            active = 1;
            break;
          case 1:
            active = 2;
            break;
          case 2:
            active = 3;
            break;
          case 3:
            active = 4;
            break;
          case 4:
            active = 5;
            break;
          case 5:
            active = 6;
            break;
          case 6:
            active = 7;
            break;
          case 7:
            active = 8;
            break;
          case 8:
            active = 1;
            break;
          case 9:
            active = 9;
            break;
          case 10:
            active = 11;
            break;
        }
      }
      return active;
    },
    stepArray() {
      let {submitTime, payTime, deliveryTime, receiptTime, evaluateTime, finishTime} = this.orderDetailConfig.detail;
      return [
        {
          title: '待服务商确认',
          description: this.dateToString(submitTime),
          date: submitTime,
        },
        {
          title: '服务商已确认，待托管资金',
          description: this.dateToString(payTime),
          date: payTime,
        },
        {
          title: '买家托管资金',
          description: this.dateToString(deliveryTime),
          date: deliveryTime,
        },
        {
          title: '服务商同意开始服务',
          description: this.dateToString(receiptTime),
          date: receiptTime,
        },
        {
          title: '服务商确认服务完成,待验收',
          description: this.dateToString(evaluateTime),
          date: evaluateTime,
        },
        {
          title: '待买家确认收到服务',
          description: this.dateToString(finishTime),
          date: finishTime,
        },
        {
          title: '买家同意支付托管资金',
          description: this.dateToString(finishTime),
          date: finishTime,
        },
        {
          title: '订单评价',
          description: this.dateToString(finishTime),
          date: finishTime,
        },
        {
          title: '订单完成',
          description: this.dateToString(finishTime),
          date: finishTime,
        },
        {
          title: '拒绝待退款',
          description: this.dateToString(finishTime),
          date: finishTime,
        },
      ];
    },
  },
  beforeMount() {
    this.orderDetailConfig.loadDetail();
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .order-item {
    border-width: 1px;
    border-color: #e5e5e5;
    border-style: solid;
    margin: 15px 0;

    .feedback {
      position: relative;

      &:before {
        position: absolute;
        content: '';
        display: block;
        height: 1px;
        background: #e5e5e5;
        top: 0;
        left: 10px;
        right: 10px;
      }
    }
  }

  .options-btn-group {
    display: flex;
    flex-direction: column;

    & .el-button + .el-button {
      margin-left: 0;
      margin-top: 5px;
    }
  }
}
</style>
